// package imports
import { useQuery } from '@tanstack/react-query';

// local imports
import { callApi } from 'services/apiWrapper';

export const conversationHistoryKeys = {
  conversationHistory: () => ['conversationHistoryList'],
  conversation: (id) => ['conversation', id],
};

export const fetchConversationHistory = async () => {
  const res = await callApi(`/api/search/chat-conv`);
  return res;
};

export const useFetchConversationHistory = () => {
  return useQuery({
    queryKey: conversationHistoryKeys.conversationHistory(),
    queryFn: () => fetchConversationHistory(),
    networkMode: 'offlineFirst',
    retry: false,
  });
};

export const fetchConversation = async (id: string) => {
  const res = await callApi(`/api/search/chat-conv?id=${id}`);
  return res;
};

export const useFetchConversation = (id) => {
  return useQuery({
    queryKey: conversationHistoryKeys.conversation(id),
    queryFn: () => fetchConversation(id),
    networkMode: 'offlineFirst',
    retry: false,
  });
};
