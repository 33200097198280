import React from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Box from '@mui/material/Box';
import { KitButton, KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@mui/material/styles';

const SummaryWithImage = ({ module }) => {
  const { fields } = module;
  const theme = useTheme();

  const router = useRouter();

  const callAction = (event) => {
    event.preventDefault();
    router.push(fields.actionButtonUrl.href);
  };

  const imageExists = fields.moduleImage && fields.moduleImage.url && fields.moduleImage.url.length > 0;

  const GrayBackground = styled.div`
    @media screen and (min-width: 992px) {
      ${fields.imageAlign === 'left' ? (imageExists ? 'flex-direction: row-reverse;' : 'flex-direction: row;') : 'flex-direction: row;'}
      padding: 5px 0 45px 0;
    }
    @media screen and (max-width: 991px) {
      padding: 0;
    }
  `;

  const Frame = styled.div`
    display: flex;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      ${fields.imageAlign === 'right' ? (imageExists ? 'flex-direction: row-reverse;' : 'flex-direction: row;') : 'flex-direction: row;'}
    }
    @media screen and (max-width: 991px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 0 20px 0;
      padding: 0;
    }
  `;

  const ContentContainer = styled.div`
    display: flex;
    @media screen and (min-width: 992px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      vertical-align: top;
      margin-top: 60px;
      width: 50%;
    }
    @media screen and (max-width: 991px) {
      text-align: left;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `;

  const ImageContainer = styled.div`
    @media screen and (min-width: 992px) {
      justify-content: left;
      margin: 30px 0 0 0;
      margin-right: 40px;
      ${imageExists ? 'max-width: 60%;' : 'display: none;'}
      ${imageExists ? 'max-height: 95%;' : 'display: none;'}
    }
    @media screen and (max-width: 991px) {
      width: 100%;
      margin: 0;
      ${imageExists ? 'width: 100%;' : 'display: none;'}
      ${imageExists ? 'height: auto;`;' : 'display: none;'}
    }
  `;

  const Title = styled.div`
    text-transform: uppercase;
    font-weight: 600;
    @media screen and (min-width: 992px) {
      margin: 15px 5px 0 5px;
      min-height: 25px;
      color: ${fields.titleColor};
      font-size: 2.375rem;
      line-height: 2.688rem;
    }
    @media screen and (max-width: 991px) {
      margin: 10px;
      color: ${fields.titleColor};
      font-size: 1.625rem;
      line-height: 1.875rem;
      padding: 0 5px;
    }
  `;

  const ButtonContainer = styled.div`
    @media screen and (min-width: 992px) {
      margin: 10px 5px 5px 5px;
    }
    @media screen and (max-width: 991px) {
      padding: 0 5px;
    }
  `;

  return (
    <React.Fragment>
      <GrayBackground
        style={{
          backgroundColor: `rgba(217, 217, 217, 0.3)`,
        }}
      >
        <Box
          sx={{
            width: '100%',
            ml: 'auto',
            mr: 'auto',
            maxWidth: { sm: '540px', md: '768px', lg: '1140px', xl: '1180px' },
          }}
        >
          <Frame>
            <ImageContainer>
              <img src={fields?.moduleImage?.url} alt={fields?.moduleImage?.text} />
            </ImageContainer>
            <ContentContainer>
              <Title>{fields.title}</Title>
              {fields.actionButtonUrl && fields.actionButtonUrl.href && fields.actionButtonUrl.href.length > 0 && (
                <ButtonContainer>
                  <Link
                    href={fields.actionButtonUrl.href}
                    passHref
                    target={fields.actionButtonUrl.target}
                    rel={`noopener noreferrer${fields.addNoFollowtoActionUrl === 'true' ? ' nofollow' : ''}`}
                  >
                    <KitButton
                      round
                      size='lg'
                      style={{
                        backgroundColor: theme.palette.primary.light,
                      }}
                    >
                      {fields.actionButtonUrl.text}
                    </KitButton>
                  </Link>
                </ButtonContainer>
              )}
            </ContentContainer>
          </Frame>
        </Box>
      </GrayBackground>
    </React.Fragment>
  );
};

export default SummaryWithImage;
