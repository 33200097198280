import { useEffect, useState, useRef } from 'react';
import { useTheme } from '@emotion/react';
import { useObservationStore } from '../../stores/observationStore';
import { ObsStyles } from '../StyledComponents';
import OffTaskDialog from './OffTaskDialog';

const ObservationOffTask = () => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const [intervalTimer, setIntervalTimer] = useState(null);
  const [showOffTask, setShowOffTask] = useState(false);
  const [offTaskNumber, setOffTaskNumber] = useState('');
  const [offTaskNumberError, setOffTaskNumberError] = useState(null);
  const offTaskLastShown = useRef(0);
  const timerLastFired = useRef(0);

  const { observation, addTimelineObservation } = useObservationStore();

  const addToTimeline = (isClosed = false) => {
    if (
      isClosed ||
      (!isNaN(offTaskNumber) && Number.parseInt(offTaskNumber, 10) >= 0 && Number.parseInt(offTaskNumber, 10) <= observation.number_of_students)
    ) {
      const timelineNote = {
        timestamp: new Date().getTime(),
        type: 'Off Task',
        tags: [isClosed ? 'X' : offTaskNumber.toString()],
        count: isClosed ? -1 : Number.parseInt(offTaskNumber, 10),
        number_of_students: observation.number_of_students,
      };
      addTimelineObservation(timelineNote);
      setShowOffTask(false);
      setOffTaskNumber('');
      setOffTaskNumberError(false);
    }
  };

  const getTimestampForMinute = (timers, minute) => {
    let totalDuration = 0;
    const currentTime = Date.now();

    for (const timer of timers) {
      const endTime = timer.timer_end === 0 ? currentTime : timer.timer_end;
      const duration = (endTime - timer.timer_start) / 60000;

      if (totalDuration + duration >= minute) {
        let minuteOffset = minute - totalDuration;
        return new Date(timer.timer_start + minuteOffset * 60000); // Convert back to ms
      }
      totalDuration += duration;
    }
    return null;
  };

  const addMissedOffTask = (observation, minute) => {
    console.log('addMissedOffTask', minute, new Date().toISOString());
    const timestamp = getTimestampForMinute(observation.timer, minute);
    if (timestamp !== null) {
      const timelineNote = {
        timestamp: new Date().getTime(),
        type: 'Off Task',
        tags: ['-'],
        count: -1,
        number_of_students: observation.number_of_students,
      };
      addTimelineObservation(timelineNote);
    }
  };

  const getCurrentObservation = () => {
    if (typeof window === 'undefined') {
      return null;
    }
    const raw = localStorage.getItem('observation-active');
    if (raw) {
      return JSON.parse(raw)?.state?.observation;
    }
    return null;
  };

  useEffect(() => {
    if (
      observation.timer &&
      observation.timer.length > 0 &&
      observation.timer[0].timer_start &&
      observation.timer[observation.timer.length - 1]?.timer_end === 0
    ) {
      const timer = setInterval(() => {
        const currentObservation = getCurrentObservation();
        if (!currentObservation) {
          return;
        }

        if (timerLastFired.current === 0) {
          timerLastFired.current = Date.now();
        }
        // Note - Safari throttles setInterval when tab is not active, cannot guarantee 1 second accuracy
        let elapsedMS = 0;
        for (let i = 0; i < currentObservation.timer.length; i++) {
          if (currentObservation.timer[i].timer_end === 0) {
            elapsedMS += Date.now() - currentObservation.timer[i].timer_start;
          } else {
            elapsedMS += currentObservation.timer[i].timer_end - currentObservation.timer[i].timer_start;
          }
        }
        let goingToShow = false;
        const elapsedSeconds = elapsedMS / 1000;
        const minute = Math.floor(elapsedSeconds / 60);
        const timeSinceShown = Date.now() - offTaskLastShown.current;

        // console.log(
        //   `Elapsed Seconds: ${elapsedSeconds} ${showOffTask} ${offTaskLastShown.current} ${timeSinceShown} ${Math.floor(
        //     Date.now() - timerLastFired.current
        //   )}`,
        //   new Date().toUTCString()
        // );

        if (
          showOffTask === false &&
          Math.floor(timeSinceShown) >= 45 &&
          Math.floor(elapsedSeconds % 60) === 0 &&
          Math.floor(Date.now() - timerLastFired.current) < 1150
        ) {
          let loggedForThisMinute = false;
          currentObservation.observations.forEach((o) => {
            if (o.type === 'Off Task') {
              loggedForThisMinute = o.session_minute === minute;
            }
          });

          if (!loggedForThisMinute) {
            setShowOffTask(true);
            goingToShow = true;
          } else {
            offTaskLastShown.current = Date.now();
          }
        }

        // check to see if there are any missed off task observations
        if (!goingToShow && Math.floor(Date.now() - timerLastFired.current) > 2000 && Math.floor(timeSinceShown) > 60) {
          const minuteArray = new Array(Math.floor(elapsedSeconds / 60)).fill(null);
          currentObservation.observations.forEach((o) => {
            if (o.type === 'Off Task') {
              minuteArray[o.session_minute - 1] = o;
            }
          });

          for (let i = 0; i < minuteArray.length; i++) {
            if (minuteArray[i] === null) {
              addMissedOffTask(currentObservation, i + 1);
            }
          }
        }
        timerLastFired.current = Date.now();

        setIntervalTimer(timer);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [observation.timer]);

  const closeDialog = () => {
    offTaskLastShown.current = Date.now();
    setShowOffTask(false);
  };

  return (
    <main>
      <OffTaskDialog
        style={styles}
        open={showOffTask}
        closeConfirm={closeDialog}
        observation={observation}
        offTaskNumber={offTaskNumber}
        setOffTaskNumber={setOffTaskNumber}
        offTaskNumberError={offTaskNumberError}
        setOffTaskNumberError={setOffTaskNumberError}
        addToTimeline={addToTimeline}
        studentCount={observation.number_of_students}
      />
    </main>
  );
};

export default ObservationOffTask;
