import { PropsWithChildren, ReactElement, ReactNode, createElement } from 'react';
import parse from 'html-react-parser';
import * as prod from 'react/jsx-runtime';
import { unified } from 'unified';
import rehypeParse from 'rehype-parse';
import rehypeReact, { Options } from 'rehype-react';
import { rehypeFilter, RehypeFilterOptions } from './rehypeFilter';

type ParseHtmlOptions = Pick<Options, 'components'> & RehypeFilterOptions;

// @ts-expect-error: the react types are missing.
const production = { Fragment: prod.Fragment, jsx: prod.jsx, jsxs: prod.jsxs };

export function parseHtml(content: string, options?: ParseHtmlOptions): ReactNode {
  const { result } = unified()
    .use(rehypeParse, {
      fragment: true,
    })
    .use(rehypeReact, production)
    .use(rehypeFilter, {
      allowElement: options?.allowElement,
      allowedElements: options?.allowedElements,
      disallowedElements: options?.disallowedElements,
      unwrapDisallowed: options?.unwrapDisallowed,
    })
    .processSync(content);

  // unwraps the outer <div> element
  return (result as ReactElement<PropsWithChildren<{}>>).props.children;
}

export function cleanUpRawHtml(content: string) {
  const parseOptions = {
    replace(domNode) {
      if (domNode.name === 'a') {
        if (
          domNode.attribs &&
          domNode.attribs.href &&
          domNode.attribs.href.startsWith('https://content.liftwithboystown.org') &&
          domNode.attribs.href.endsWith('.pdf')
        ) {
          // check to see if ther is a rel="nofollow" attribute
          let addedNoFollow = false;
          for (const key in domNode.attribs) {
            if (key === 'rel') {
              if (!domNode.attribs[key].includes('nofollow')) {
                domNode.attribs[key] += ' nofollow';
                addedNoFollow = true;
              } else if (domNode.attribs[key].includes('nofollow')) {
                addedNoFollow = true;
              }
            }
          }
          if (!addedNoFollow) {
            domNode.attribs['rel'] = 'nofollow';
          }
          // put the a back together inlcuding any added rel=nofollow
          let a = '<a ';
          for (const key in domNode.attribs) {
            a += `${key}="${domNode.attribs[key]}" `;
          }
          a += `>${domNode.children[0].data}</a>`;
          return parse(a);
        }
      }
    },
  };

  const parsed = parse(content, parseOptions);
  return parsed;
}
