// package imports
import { create } from 'zustand';
import { produce } from 'immer';
import { persist, createJSONStorage } from 'zustand/middleware';

// local imports
import { ObservationInstance, getNewObservationInstance, timelineObservation } from 'types/ObservationTypes';

type State = {
  observation: ObservationInstance;
  editTimelineItem: timelineObservation | null;
};

type PatchObject = {
  name: string;
  value: string | number | boolean | Date | object;
};

type Actions = {
  patchObservation: (prop: Array<PatchObject>) => void;
  setObservation: (observation: ObservationInstance) => void;
  startTimer: (tag: string) => void;
  stopTimer: (tag: string) => void;
  updateStudentCount: (num: number) => void;
  addTimelineObservation: (observation: timelineObservation) => void;
  updateTimelineObservation: (observation: timelineObservation) => void;
  removeTimelineObservation: (observation: timelineObservation) => void;
  patchTimelineObservation: (prop: PatchObject) => void;
  setDirtyObject: (type: string) => void;
  setEditTimelineItem: (item: timelineObservation | null) => void;
};

/**
 * Custom hook for managing the observation store.
 * @returns An object containing state and actions for managing the observation.
 */
export const useObservationStore = create<State & Actions>()(
  persist(
    (set) => ({
      observation: getNewObservationInstance(),
      editTimelineItem: null,
      patchObservation: (prop: Array<PatchObject>) =>
        set(
          produce((draft) => {
            prop.forEach((prop) => (draft.observation[prop.name] = prop.value));
          })
        ),
      setObservation: (observation: ObservationInstance) =>
        set(
          produce((draft) => {
            if (observation) {
              if (typeof observation.number_of_students !== 'number') {
                observation.number_of_students = parseInt(observation.number_of_students, 10);
              }
              if (isNaN(observation.number_of_students)) {
                // this is a bit of a hack. somehow users are getting a non-number in the number_of_students field
                // we haven't been able to reproduce it, so this is a stop-gap measure
                observation.number_of_students = 1;
                observation.defaulted_num_students = true;
              }
            }
            draft.observation = observation;
          })
        ),
      startTimer: (tag: string) =>
        set(
          produce((draft) => {
            if (!draft.observation.timer) draft.observation.timer = [];
            const timeStart = new Date().getTime();
            draft.observation.timer.push({
              timer_start: timeStart,
              timer_end: 0,
            });
            if (tag?.length > 0) {
              if (!draft.observation.observations) draft.observation.observations = [];
              let timerEvent: timelineObservation = {
                timestamp: timeStart,
                type: 'Timer',
                tags: [],
                session_minute: null,
                note: '',
              };
              timerEvent.tags.push(tag);
              timerEvent.session_minute = getSessionMinute(draft.observation);
              draft.observation.observations.push(timerEvent);
            }
          })
        ),
      stopTimer: (tag: string) =>
        set(
          produce((draft) => {
            const lastTimer = draft.observation.timer[draft.observation.timer.length - 1];
            lastTimer.timer_end = new Date().getTime();
            if (tag?.length > 0) {
              if (!draft.observation.observations) draft.observation.observations = [];
              let timerEvent: timelineObservation = {
                timestamp: lastTimer.timer_end,
                type: 'Timer',
                tags: [],
                session_minute: null,
                note: '',
              };
              timerEvent.tags.push(tag);
              timerEvent.session_minute = getSessionMinute(draft.observation);
              draft.observation.observations.push(timerEvent);
            }
          })
        ),
      updateStudentCount: (num) =>
        set(
          produce((draft) => {
            if (typeof draft.observation.number_of_students !== 'number') {
              draft.observation.number_of_students = parseInt(draft.observation.number_of_students, 10);
            }
            if (isNaN(draft.observation.number_of_students)) {
              draft.observation.number_of_students = 0;
            }
            const temp = draft.observation.number_of_students + num;
            if (temp > 0) {
              draft.observation.number_of_students = temp;
            }
          })
        ),
      setDirtyObject: (type) =>
        set(
          produce((draft) => {
            draft.observation.dirtyObject = type;
          })
        ),
      patchTimelineObservation: (prop: PatchObject) =>
        set(
          produce((draft) => {
            draft.editTimelineItem[prop.name] = prop.value;
          })
        ),
      addTimelineObservation: (observation: timelineObservation) =>
        set(
          produce((draft) => {
            if (!draft.observation.observations) draft.observation.observations = [];
            observation.session_minute = getSessionMinute(draft.observation);
            draft.observation.observations.push(observation);
          })
        ),
      updateTimelineObservation: (observation: timelineObservation) =>
        set(
          produce((draft) => {
            const ix = draft.observation.observations.findIndex((o) => o.timestamp === observation.timestamp);
            if (ix === -1) {
              throw new Error('Could not find timeline observation to update');
            }
            observation.session_minute = draft.observation.observations[ix].session_minute;
            draft.observation.observations[ix] = observation;
          })
        ),
      removeTimelineObservation: (observation: timelineObservation) =>
        set(
          produce((draft) => {
            const ix = draft.observation.observations.findIndex((o) => o.timestamp === observation.timestamp);
            if (ix === -1) {
              throw new Error('Could not find timeline observation to update');
            }
            draft.observation.observations.splice(ix, 1);
          })
        ),
      setEditTimelineItem: (item: timelineObservation) =>
        set(
          produce((draft) => {
            draft.editTimelineItem = item;
          })
        ),
    }),
    {
      name: 'observation-active', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

const getSessionMinute = (observation: ObservationInstance) => {
  let elapsedMS = 0;
  for (let i = 0; i < observation.timer.length; i++) {
    if (observation.timer[i].timer_end === 0) {
      elapsedMS += Date.now() - observation.timer[i].timer_start;
    } else {
      elapsedMS += observation.timer[i].timer_end - observation.timer[i].timer_start;
    }
  }
  const elapsedSeconds = elapsedMS / 1000;
  const minute = Math.floor(elapsedSeconds / 60);
  return minute;
};
