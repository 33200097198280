// package imports
import { useTheme } from '@emotion/react';
import { Box, IconButton } from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AssistantIcon from '@mui/icons-material/Assistant';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import TelegramIcon from '@mui/icons-material/Telegram';

// local imports
import { parseMessage } from './message-parser';
import { flashDotStyles } from './styles';

const AssistantMessage = ({ message, index, handleFollowUp }) => {
  const theme = useTheme();
  const parsedMessage = parseMessage(message);

  const parseCitation = (content) => {
    const parts = content.split(' ');
    if (parts.length === 3 && parseInt(parts[2], 10) !== NaN && parseInt(parts[2], 10) > 0) {
      return (
        <>
          <a style={{ textDecoration: 'underline' }} href={parts[0]} target='_blank' rel='noopener noreferrer'>
            {parts[0]}
          </a>
          <span> ({`${parts[1]} ${parts[2]}`})</span>
        </>
      );
    } else {
      return (
        <a style={{ textDecoration: 'underline' }} href={parts[0]} target='_blank' rel='noopener noreferrer'>
          {parts[0]}
        </a>
      );
    }
  };

  return (
    <Box
      key={index}
      sx={{ display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', flexDirection: 'column', marginTop: '1rem', marginBottom: '1rem' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.mediumDark,
          borderRadius: '1rem',
        }}
      >
        <Box sx={{ alignSelf: 'flex-start', marginLeft: '1rem', paddingTop: '1rem' }}>
          <AssistantIcon color='primary' />
        </Box>
        <Box
          sx={{
            padding: '1rem',
            color: theme.palette.primary.dark,
          }}
        >
          {parsedMessage.content?.length === 0 && (
            <Box sx={{ marginLeft: '1rem', marginRight: '1rem', marginTop: '5px' }}>
              <style>{flashDotStyles(theme)}</style>
              <div className='dot-flashing'></div>
            </Box>
          )}
          <div dangerouslySetInnerHTML={{ __html: parsedMessage.content }}></div>
          <Box sx={{ display: parsedMessage?.citations?.length > 0 ? 'flex' : 'none', flexDirection: 'column' }}>
            <Box sx={{ marginTop: '1rem' }}>Citations:</Box>
            <ol type='1' role='list' style={{ listStylePosition: 'inside', listStyleType: 'decimal' }}>
              {parsedMessage?.citations?.map((citation, index) => (
                <li key={index}>{parseCitation(citation)}</li>
              ))}
            </ol>
          </Box>
          <Box sx={{ display: parsedMessage?.followupQuestions?.length > 0 ? 'flex' : 'none', flexDirection: 'column' }}>
            <Box sx={{ marginTop: '1rem' }}>Follow-up Questions:</Box>
            <ol type='1' role='list' style={{ listStylePosition: 'inside', listStyleType: 'decimal' }}>
              {parsedMessage?.followupQuestions?.map((question, index) => (
                <li key={index}>
                  <span>{question}</span>
                  <IconButton onClick={() => handleFollowUp(question)}>
                    <TelegramIcon color='primary' />
                  </IconButton>
                </li>
              ))}
            </ol>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AssistantMessage;
