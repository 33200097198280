import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';

const UserMessage = ({ message, index }) => {
  const theme = useTheme();
  return (
    <Box key={index} sx={{ display: 'flex', flexWrap: 'wrap', alignContent: 'flex-end', flexDirection: 'column' }}>
      <Box
        sx={{ backgroundColor: theme.palette.primary.light2, color: theme.palette.common.white, borderRadius: '1rem', padding: '1rem', width: '70%' }}
      >
        <div>{message.content}</div>
      </Box>
      <Box sx={{ alignSelf: 'flex-end', marginRight: '1rem', color: theme.palette.primary.main, fontSize: '.85rem' }}>You</Box>
    </Box>
  );
};

export default UserMessage;
