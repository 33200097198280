// package imports
import { KitCard, KitCardBody } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import { Box, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

// local imports
import RedirectDialog from 'components/common/subcomponents/RedirectDialog';
import { useSession } from 'next-auth/react';
import Correction from './components/dataEntry/Correction';
import ObservationOffTask from './components/dataEntry/ObservationOffTask';
import ObservationSessionNote from './components/dataEntry/ObservationSessionNote';
import ObservationTimeline from './components/dataEntry/ObservationTimeline';
import ObservationTimer from './components/dataEntry/ObservationTimer';
import Praise from './components/dataEntry/Praise';
import Prevention from './components/dataEntry/Prevention';
import { useObservationStore } from './stores/observationStore';
import { canAccessObservations, observationRedirectMessage, observationRedirectPage } from './utils/ObservationUtils';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const scrollToTop = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
};

const ObservationDataEntry = ({ module: { fields } }) => {
  const { data: session, status } = useSession();
  const router = useRouter();
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState(null);
  const { observation, startTimer } = useObservationStore();

  useEffect(() => {
    if (!observation) {
      router.push(fields?.observationLanding?.href);
    } else if (observation.status === 'Complete') {
      // redirect to the observation complete page
      router.push(fields?.sessionReview?.href);
    }
  }, []);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Box
      sx={{
        paddingTop: '1rem',
        backgroundColor: theme.palette.obsBlue.light,
        paddingBottom: '1rem',
      }}
    >
      <RedirectDialog
        open={(status !== 'loading' && status !== 'authenticated') || !canAccessObservations(session, status)}
        message={observationRedirectMessage(session, status)}
        redirectTo={observationRedirectPage(session, status)}
        buttonText={session?.user ? 'OK' : 'Sign In'}
      />

      <Box sx={{ height: '265px', display: 'grid', gridTemplateColumns: '2fr 335px', gap: '.5rem' }}>
        <Box sx={{ backgroundColor: theme.palette.obsBlue.light }}>
          <ObservationTimeline />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <ObservationTimer nextUrl={fields?.sessionEnd?.href} />
          <ObservationSessionNote />
          <ObservationOffTask />
        </Box>
      </Box>
      <KitCard style={{ width: '100%', marginTop: '1rem', marginBottom: 0 }}>
        <KitCardBody>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
            <Prevention scrollToTop={scrollToTop} />
            <Praise scrollToTop={scrollToTop} />
            <Correction scrollToTop={scrollToTop} />
          </Box>
        </KitCardBody>
      </KitCard>
      {/* <pre>{JSON.stringify(fields)}</pre> */}
      {/* <pre>{JSON.stringify(observation)}</pre>
      <pre>{JSON.stringify(observationQuery)}</pre> */}

      <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ObservationDataEntry;
