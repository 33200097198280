import React from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Box from '@mui/material/Box';
import { KitButton, KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@mui/material/styles';

const SummaryWithVideo = ({ module }) => {
  const { fields } = module;
  const theme = useTheme();

  const router = useRouter();

  const callAction = (event) => {
    event.preventDefault();
    router.push(fields.actionButtonUrl.href);
  };

  const videoExists = fields.videoScript && fields.videoScript.length > 0;

  const GrayBackground = styled.div`
    padding: 40px 10px;
    @media screen and (min-width: 900px) {
      ${fields.videoAlign === 'left' ? (videoExists ? 'flex-direction: row-reverse;' : 'flex-direction: row;') : 'flex-direction: row;'}
    }
    @media screen and (max-width: 899px) {
      display: none;
    }
  `;

  const Container = styled.div`
    position: relative;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: flex-start;
    @media screen and (min-width: 900px) {
      ${fields.videoAlign === 'left' ? (videoExists ? 'flex-direction: row-reverse;' : 'flex-direction: row;') : 'flex-direction: row;'}
    }
    @media screen and (max-width: 899px) {
      display: none;
    }
  `;

  const MobileContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    @media screen and (min-width: 900px) {
      display: none;
    }
    @media screen and (max-width: 899px) {
    }
  `;

  const ContentContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    flex: 1;
    vertical-align: top;
    margin-top: 60px;
    width: 38%;
    @media screen and (min-width: 900px) {
      ${fields.videoAlign === 'left' ? (videoExists ? 'text-align: left;' : 'text-align: right;') : 'text-align: right;'}
    }
    @media screen and (max-width: 899px) {
      display: none;
    }
  `;

  const VideoContainer = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    margin: 50px 0 0 10px;
    @media screen and (min-width: 900px) {
      width: 62%;
      ${videoExists ? 'max-width: 60%;' : 'display: none;'}
      ${videoExists ? 'max-height: 95%;' : 'display: none;'}
    }
    @media screen and (max-width: 899px) {
      display: none;
    }
  `;

  const MobileVideoContainer = styled.div`
    width: 100%;
    margin: 0 0 0 20px;
    @media screen and (min-width: 900px) {
      display: none;
    }
    @media screen and (max-width: 899px) {
      ${videoExists ? 'width: 100%;' : 'display: none;'}
      ${videoExists ? 'height: auto;`;' : 'display: none;'}
    }
  `;

  const Title = styled.div`
    min-height: 25px;
    font-weight: 600;
    margin: 15px 5px 0 5px;
    @media screen and (min-width: 900px) {
      color: ${fields.titleColor};
      font-size: 2.25rem;
      line-height: 2.5rem;
    }
    @media screen and (max-width: 899px) {
    }
  `;

  const MobileTitle = styled.div`
    font-weight: 600;
    margin: 10px 5px;
    @media screen and (min-width: 900px) {
      display: none;
    }
    @media screen and (max-width: 899px) {
      color: ${fields.titleColor};
      font-size: 1.35rem;
      line-height: 1.55rem;
      padding: 0 5px;
    }
  `;

  const SummaryDescription = styled.div`
    ${fields.videoAlign === 'left' ? (videoExists ? 'margin: 30px 10% 30px 10px;' : 'margin: 30px 10px 30px 25%;') : 'margin: 30px 10px 30px 25%;'}
    font-weight: 600;
    @media screen and (min-width: 900px) {
      color: ${fields.descriptionColor};
      font-size: 2.125rem;
      line-height: 2.688rem;
    }
    @media screen and (max-width: 899px) {
      display: none;
    }
  `;

  const MobileSummaryDescription = styled.div`
    font-weight: 600;
    width: 75%;
    margin: 10px 0 20px 0;
    @media screen and (min-width: 900px) {
      display: none;
    }
    @media screen and (max-width: 899px) {
      color: ${fields.descriptionColor};
      font-size: 1.265rem;
      line-height: 1.45rem;
      padding: 0 5px;
    }
  `;

  const ButtonContainer = styled.div`
    margin: 30px 5px;
    @media screen and (max-width: 899px) {
      display: none;
    }
  `;

  const MobileButtonContainer = styled.div`
    @media screen and (min-width: 900px) {
      display: none;
    }
    @media screen and (max-width: 899px) {
      padding: 0 25px;
    }
  `;

  return (
    <React.Fragment>
      <GrayBackground
        style={{
          backgroundColor: `rgba(217, 217, 217, 0.3)`,
        }}
      >
        <Box
          sx={{
            width: '100%',
            ml: 'auto',
            mr: 'auto',
            maxWidth: { sm: '540px', md: '768px', lg: '1140px', xl: '1180px' },
          }}
        >
          <Container>
            <ContentContainer>
              <Title>{fields.title}</Title>
              <SummaryDescription>{fields.description}</SummaryDescription>
              {fields.actionButtonUrl && fields.actionButtonUrl.href && fields.actionButtonUrl.href.length > 0 && (
                <ButtonContainer>
                  <Link
                    href={fields.actionButtonUrl.href}
                    passHref
                    target={fields.actionButtonUrl.target}
                    rel={`noopener noreferrer${fields.addNoFollowtoActionUrl === 'true' ? ' nofollow' : ''}`}
                  >
                    <KitButton
                      round
                      size='lg'
                      style={{
                        backgroundColor: theme.palette.primary.light,
                      }}
                    >
                      {fields.actionButtonUrl.text}
                    </KitButton>
                  </Link>
                </ButtonContainer>
              )}
            </ContentContainer>
            <VideoContainer>
              <div className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.videoScript)} />
            </VideoContainer>
          </Container>
        </Box>
      </GrayBackground>
      <MobileContainer>
        <MobileVideoContainer>
          <div className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.videoScript)} />
        </MobileVideoContainer>
        <MobileTitle>{fields.title}</MobileTitle>
        <MobileSummaryDescription>{fields.description}</MobileSummaryDescription>
        <MobileButtonContainer>
          <Link href={fields.actionButtonUrl.href} passHref legacyBehavior>
            <KitButton
              round
              style={{
                backgroundColor: theme.palette.primary.light,
              }}
            >
              <a href={fields.actionButtonUrl.href} target={fields.actionButtonUrl.target} rel='noopener noreferrer'>
                {fields.actionButtonUrl.text}
              </a>
            </KitButton>
          </Link>
        </MobileButtonContainer>
      </MobileContainer>
    </React.Fragment>
  );
};

export default SummaryWithVideo;
