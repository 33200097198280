export const flashDotStyles = (theme) => {
  return `
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.primary.main};
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.primary.main};
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.primary.main};
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: ${theme.palette.primary.main};
  }
  50%, 100% {
    background-color: ${theme.palette.primary.lightest};
  }
}
`;
};
