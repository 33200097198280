import React, { useState } from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import { KitButton, KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@mui/material/styles';
import GatedResourceDrawer from 'components/common/subcomponents/GatedResources/GatedResourceDrawer';
import GatedResourceModal from 'components/common/subcomponents/GatedResources/GatedResourceModal';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VideoDialogWithX from './pageComponents/VideoDialogWithX';

import { gatedResourceCheck, logResourceAccess } from 'services/gatedResourceUtils';

const SummaryWithImageAndWebinar = ({ module }) => {
  const { fields } = module;

  console.log('........SummaryWithImageAndWebinar fields: ', fields);

  const theme = useTheme();

  const router = useRouter();

  const [showVideoDialog, setShowVideoDialog] = useState(false);
  const [gatedDrawerState, setGatedDrawerState] = useState({ open: false, remaining: 0 });
  const [gatedModalOpen, setGatedModalOpen] = useState(false);
  const { data: session, status } = useSession();

  const hideVideoDialog = () => {
    setShowVideoDialog(false);
  };

  const callAction = async (event) => {
    if (fields.enforceFreeResourceGating === 'true') {
      if (!session?.user) {
        const remaining = gatedResourceCheck(fields?.actionButtonUrl?.href);

        console.log('free resources remaining: ', remaining);

        if (remaining < 0) {
          // show overlay
          setGatedModalOpen(true);
          return;
        } else {
          setGatedDrawerState({ open: true, remaining });
          displayActionItem();
        }
      } else {
        displayActionItem();
      }
    } else {
      displayActionItem();
    }
  };

  const displayActionItem = async () => {
    if (fields.actionButtonUrl.target === '_blank') {
      const win = window.open(fields.actionButtonUrl.href, fields.actionButtonUrl.target);
      win.focus();
    } else {
      router.push(fields.actionButtonUrl.href);
    }
    await logResourceAccess(fields?.resourceType, fields?.resourceName, fields?.actionButtonUrl?.href, 'resource-cta');
  };

  const showVideo = async (event) => {
    event.preventDefault();
    if (fields.enforceFreeResourceGating === 'true') {
      await logResourceAccess('recorded webinar', fields?.webinarName, fields?.actionButtonUrl?.href);
      if (!session?.user) {
        const remaining = gatedResourceCheck(fields?.actionButtonUrl?.href);

        if (remaining < 0) {
          // show overlay
          setGatedModalOpen(true);
          return;
        } else {
          setGatedDrawerState({ open: true, remaining });
        }
      }
    }
    setShowVideoDialog(true);

    await logResourceAccess(fields?.resourceType, fields?.resourceName, fields?.actionButtonUrl?.href, 'resource-cta');
  };

  const imageExists = fields.moduleImage && fields.moduleImage.url && fields.moduleImage.url.length > 0;

  const GrayBackground = styled.div`
    @media screen and (min-width: 992px) {
      ${fields.imageAlign === 'left' ? (imageExists ? 'flex-direction: row-reverse;' : 'flex-direction: row;') : 'flex-direction: row;'}
      padding: 5px 0 45px 0;
    }
    @media screen and (max-width: 991px) {
      padding: 0;
    }
  `;

  const Frame = styled.div`
    display: flex;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      ${fields.imageAlign === 'right' ? (imageExists ? 'flex-direction: row-reverse;' : 'flex-direction: row;') : 'flex-direction: row;'}
    }
    @media screen and (max-width: 991px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 0 20px 0;
      padding: 0;
    }
  `;

  const ContentContainer = styled.div`
    display: flex;
    @media screen and (min-width: 992px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      vertical-align: top;
      margin-top: 60px;
      width: 50%;
    }
    @media screen and (max-width: 991px) {
      text-align: left;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `;

  const ImageContainer = styled.div`
    @media screen and (min-width: 992px) {
      justify-content: left;
      margin: 30px 0 0 0;
      margin-right: 40px;
      ${imageExists ? 'max-width: 60%;' : 'display: none;'}
      ${imageExists ? 'max-height: 95%;' : 'display: none;'}
    }
    @media screen and (max-width: 991px) {
      width: 100%;
      margin: 0;
      ${imageExists ? 'width: 100%;' : 'display: none;'}
      ${imageExists ? 'height: auto;`;' : 'display: none;'}
    }
  `;

  const Title = styled.div`
    text-transform: uppercase;
    font-weight: 600;
    @media screen and (min-width: 992px) {
      margin: 15px 5px 0 5px;
      min-height: 25px;
      color: ${fields.titleColor};
      font-size: 2.375rem;
      line-height: 2.688rem;
    }
    @media screen and (max-width: 991px) {
      margin: 10px;
      color: ${fields.titleColor};
      font-size: 1.625rem;
      line-height: 1.875rem;
      padding: 0 5px;
    }
  `;

  const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    @media screen and (min-width: 992px) {
      margin: 10px 5px 5px 5px;
    }
    @media screen and (max-width: 991px) {
      padding: 0 5px;
    }
  `;

  return (
    <React.Fragment>
      <GrayBackground
        style={{
          backgroundColor: `rgba(217, 217, 217, 0.3)`,
        }}
      >
        <Box
          sx={{
            width: '100%',
            ml: 'auto',
            mr: 'auto',
            maxWidth: { sm: '540px', md: '768px', lg: '1140px', xl: '1180px' },
          }}
        >
          <Frame>
            <ImageContainer>
              <img src={fields?.moduleImage?.url} alt={fields?.moduleImage?.text} />
            </ImageContainer>
            <ContentContainer>
              <Title>{fields.title}</Title>
              {fields.viewer === 'popup' && fields?.embedVideoScript && fields.embedVideoScript.length > 0 ? (
                <KitButton
                  round
                  size='lg'
                  style={{
                    backgroundColor: theme.palette.primary.light,
                  }}
                  onClick={(e) => showVideo(e)}
                >
                  {fields.actionButtonUrl.text}
                </KitButton>
              ) : (
                fields.actionButtonUrl &&
                fields.actionButtonUrl.href &&
                fields.actionButtonUrl.href.length > 0 && (
                  <KitButton
                    round
                    size='lg'
                    style={{
                      backgroundColor: theme.palette.primary.light,
                    }}
                    onClick={(e) => callAction(e)}
                  >
                    {fields.actionButtonUrl.text}
                  </KitButton>
                )
              )}
            </ContentContainer>
            <VideoDialogWithX
              size='md'
              content={
                <DialogContent>
                  <KitGridContainer direction='row'>
                    <KitGridItem xs={12} align='left'>
                      <VideoContainer>
                        <EmbedContainer
                          id='embed-container'
                          className='prose max-w-full mx-auto'
                          dangerouslySetInnerHTML={renderHTML(fields?.embedVideoScript)}
                        />
                        <YouTubeLink>
                          <a
                            id='video-link'
                            href={fields?.actionButtonUrl.href}
                            target={fields?.actionButtonurl?.target}
                            rel={`noreferrer${fields.addNoFollowtoActionUrl === 'true' ? ' nofollow' : ''}`}
                          >
                            <KitButton
                              round
                              size='sm'
                              color='youtube'
                              style={{ margin: '0', padding: '7px 40px', fontSize: '0.90rem', opacity: 1.0 }}
                            >
                              <React.Fragment key='.0'>
                                <PlayArrowIcon color='white' /> View Video
                              </React.Fragment>
                            </KitButton>
                          </a>
                        </YouTubeLink>
                      </VideoContainer>
                    </KitGridItem>
                  </KitGridContainer>
                  <ButtonContainer>
                    <KitButton
                      round
                      size='sm'
                      color='primary'
                      onClick={hideVideoDialog}
                      style={{ margin: '0', padding: '7px 40px', fontSize: '0.90rem', opacity: 1.0 }}
                    >
                      Close
                    </KitButton>
                  </ButtonContainer>
                </DialogContent>
              }
              ariaLabel='Pop-up Video Viewer'
              showValue={showVideoDialog}
              showMethod={hideVideoDialog}
            />
            <GatedResourceDrawer
              open={gatedDrawerState.open}
              remainingCount={gatedDrawerState.remaining}
              toggleDrawer={(e) => setGatedDrawerState({ ...gatedDrawerState, open: e })}
            />
            <GatedResourceModal open={gatedModalOpen} toggleOpen={(e) => setGatedModalOpen(e)} />
          </Frame>
        </Box>
      </GrayBackground>
    </React.Fragment>
  );
};

export default SummaryWithImageAndWebinar;

const VideoContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
  .video {
    aspect-ratio: 16 / 9;
    width: 800px;
  }
`;

const EmbedContainer = styled.div`
  @media screen and (min-width: 992px) {
    width: 800px;
  }
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

const YouTubeLink = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 899px) {
  }
  @media screen and (min-width: 900px) {
    display: none;
  }
`;
