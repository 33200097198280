import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import Link from 'next/link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { getIcon, getColorByAgilityName } from '../../services/agilityTranslations';
import ArticleIcon from '@mui/icons-material/Article';
import { KitGridContainer, KitGridItem, KitButton } from '@boystownorg/bi-cms-component-lib';

const VideoWithIconTitleDescButton = ({ module }) => {
  const { fields } = module;

  const [videoSelected, setVideoSelected] = useState(null);

  const showDocumentLink = (url, iconColor) => {
    return (
      <ListItemIcon>
        <Link href={url.href} passHref legacyBehavior>
          <a target={url.target} rel='noopener noreferrer'>
            <ListText style={{ color: iconColor }}>
              <ArticleIcon /> {url.text}
            </ListText>
          </a>
        </Link>
      </ListItemIcon>
    );
  };

  useEffect(() => {
    if (!videoSelected) {
      if (fields && fields.linkList && fields.linkList.length > 0) {
        setVideoSelected(fields.linkList[0]);
      }
    }
  }, [fields]);

  const [textAlign, setTextAlign] = useState('right');

  const VideoContainer = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    .video {
      aspect-ratio: 16 / 9;
    }
    @media screen and (max-width: 899px) {
      display: none;
    }
  `;

  const MobileVideoContainer = styled.div`
    background-color: #fff;
    flex-grow: 0;
    flex-shrink: 1;
    .video {
      aspect-ratio: 16 / 9;
      width: 100%;
    }
    @media screen and (min-width: 900px) {
      display: none;
    }
  `;

  const EmbedContainer = styled.div`
    @media screen and (max-width: 899px) {
      ${videoSelected ? 'width: 450px; minHeight: 230px;' : 'display: none;'}
    }
    @media screen and (min-width: 900px) {
      margin: 0 0 50px 0;
      ${videoSelected ? 'width: 550px; height: 300px;' : 'display: none;'}
    }
  `;

  const ContentContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    flex: 50%;
    vertical-align: top;
    @media screen and (max-width: 899px) {
      padding-right: 30px;
    }
    @media screen and (min-width: 900px) {
      padding: 20px 100px 0 0;
    }
  `;

  const ListContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    @media screen and (max-width: 899px) {
      padding: 0 20px 0 50px;
      width: 95%;
    }
    @media screen and (min-width: 900px) {
      padding: 0 40px;
      width: 80%;
      ${fields.textAlign === 'left' ? (videoSelected ? 'flex-direction: row-reverse;' : 'flex-direction: row;') : 'flex-direction: row;'}
    }
  `;

  const DocumentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const ButtonContainer = styled.div`
    margin: 40px 5px;
    @media screen and (max-width: 899px) {
      padding: 0 25px;
    }
  `;

  const Title = styled.div`
    font-weight: 600;
    margin: 0 5px 10px 5px;
    text-transform: uppercase;
    @media screen and (max-width: 899px) {
      color: ${fields.textColor};
      font-size: 1.75rem;
      line-height: 1.95rem;
    }
    @media screen and (min-width: 900px) {
      color: ${fields.textColor};
      font-size: 2.3rem;
      line-height: 3rem;
    }
  `;

  const Description = styled.div`
    margin: 10px 5px;
    @media screen and (max-width: 899px) {
      color: ${fields.textColor};
      font-size: 1rem;
      line-height: 1.3rem;
    }
    @media screen and (min-width: 900px) {
      color: ${fields.textColor};
      font-size: 1.25rem;
      line-height: 1.6rem;
    }
  `;
  return (
    <React.Fragment>
      <Container
        style={{
          backgroundImage: `linear-gradient(rgba(0, 58, 93, 0.95),rgba(0, 58, 93, 0.8)), url(${fields.backgroundImage.url})`,
        }}
      >
        <ListContainer>
          <VideoContainer>
            {videoSelected && videoSelected.fields && videoSelected.fields.webinarEmbed && (
              <EmbedContainer className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(videoSelected.fields.webinarEmbed)} />
            )}
            <DocumentContainer>
              <DocumentLink>
                {videoSelected &&
                  videoSelected.fields &&
                  videoSelected.fields.documentUrl &&
                  showDocumentLink(videoSelected.fields.documentUrl, fields.textColor)}
              </DocumentLink>
            </DocumentContainer>
          </VideoContainer>
          <ContentContainer>
            <ListItemButton align='left' style={{ width: '100%', padding: 0, margin: 0, marginBottom: 5 }}>
              <ListItemIcon style={{ padding: 0, margin: 0 }}>{getIcon(fields.iconName, fields.iconColor, '3.00rem')}</ListItemIcon>
            </ListItemButton>
            <Title>{fields.title}</Title>
            <Description>{fields.description}</Description>
            <ActionArea>
              <KitGridContainer direction='row' style={{ width: '100%' }}>
                {fields.showActionButton && (
                  <KitGridItem xs={12} align='left'>
                    <Link
                      href={fields.actionButtonUrl?.href}
                      passHref
                      rel={`noopener noreferrer${fields.addNoFollowtoActionUrl === 'true' ? ' nofollow' : ''}`}
                    >
                      <KitButton
                        size='sm'
                        round
                        color={fields.actionButtonColor}
                        style={{
                          color: '#fff',
                          fontWeight: 400,
                          padding: '10px 30px',
                          fontSize: '1.20rem',
                        }}
                      >
                        {fields.actionButtonText}
                      </KitButton>
                    </Link>
                  </KitGridItem>
                )}
              </KitGridContainer>
            </ActionArea>
          </ContentContainer>
        </ListContainer>
      </Container>
      <MobileVideoContainer>
        {videoSelected && videoSelected.fields && videoSelected.fields.webinarEmbed && (
          <EmbedContainer className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(videoSelected.fields.webinarEmbed)} />
        )}
        <DocumentContainer>
          <DocumentLink>
            {videoSelected &&
              videoSelected.fields &&
              videoSelected.fields.documentUrl &&
              showDocumentLink(videoSelected.fields.documentUrl, fields.textColor)}
          </DocumentLink>
        </DocumentContainer>
      </MobileVideoContainer>
    </React.Fragment>
  );
};

VideoWithIconTitleDescButton.getCustomInitialProps = async function ({ agility, languageCode, channelName }) {
  const api = agility;

  let contentItem = null;

  try {
    let list = await api.getContentList({
      referenceName: 'videoWithBullets',
      languageCode: languageCode,
      expandAllContentLinks: true,
      take: 1,
    });

    if (list && list.items && list.items.length > 0) {
      contentItem = list;
    } else {
      return null;
    }
  } catch (error) {
    if (console) console.error('Could not load Video with Bullets video List.', error);
    return null;
  }

  return {
    fields: contentItem,
  };
};

export default VideoWithIconTitleDescButton;

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    padding: '55px 0 10px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '35px 0 40px 0',
  },
}));

const ListText = styled('div')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '0.90rem',
  lineHeight: '1.00rem',
}));

const HandoutText = styled('div')(({ theme }) => ({
  fontWeight: 300,
  fontSize: '0.90rem',
  lineHeight: '1.00rem',
}));

const DocumentLink = styled('div')(({ theme }) => ({
  justifyContent: 'center',
}));

const ActionArea = styled('div')(({ theme }) => ({
  flexGrow: 0,
  padding: '10px 0 10px 2px',
}));
