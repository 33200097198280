import { z } from 'zod';

export const ObservationEventSchema = z.object({
  id: z.string().uuid().min(36).max(36).optional(),
  start_date: z.coerce.date(),
  end_date: z.coerce.date(),
  school_year_section: z.string().optional(),
  state: z.string(),
  district_id: z.string().uuid(),
  district_name: z.string(),
  agency_id: z.string().uuid(),
  agency_name: z.string(),
  department: z.array(z.string()),
  created_at: z.number().optional(),
  updated_at: z.number().optional(),
  source: z.string().optional(),
  updated_by: z.string().optional(),
  observers: z.array(z.string()).optional(),
});

export type ObservationEvent = z.infer<typeof ObservationEventSchema>;

export type ObservationListSummary = {
  id: string;
  start_date: Date;
  end_date: Date;
  school_year_section?: string;
  district_id: string;
  district_name: string;
  agency_id?: string;
  agency_name?: string;
  state: string;
  department: string[];
  observers: string[];
  observers_email: string[];
  num_observations: number;
};

export type ObservationEventInstanceList = {
  event: ObservationEvent | null;
  instances: ObservationInstance[];
};

export const ObservationInstanceBaseSchema = z.object({
  id: z.string().uuid().min(36).max(36).optional(),
  event_id: z.string().uuid().min(36).max(36),
  classroom_type: z.string(),
  district_id: z.string().uuid(),
  district_name: z.string(),
  agency_id: z.string().uuid().optional(),
  agency_name: z.string().optional(),
  teacher: z.string(),
  grade: z.string(),
  subject: z.string(),
  other_subject: z.string().optional(),
  number_of_students: z.coerce.number(),
  activity: z.array(z.string()),
  other_activity: z.string().optional(),
  phys_env_notes: z.string().optional(),
  skills_posters: z.boolean(),
  classroom_rules: z.boolean(),
  schoolwide_rules: z.boolean(),
  classroom_procedures: z.boolean(),
  schoolwide_procedures: z.boolean(),
  consequences: z.boolean(),
  daily_schedule: z.boolean(),
  cards_on_desk: z.boolean(),
  students_write_cards: z.boolean(),
  created_at: z.number().optional(),
  updated_at: z.number().optional(),
  observer_email: z.string().email().optional(),
  observer_name: z.string().optional(),
  status: z.enum(['In Progress', 'Under Review', 'Complete']).optional(),
  room_number: z.string().optional(),
  updated_by: z.string().optional(),
});

export type ObservationInstanceBase = z.infer<typeof ObservationInstanceBaseSchema>;

const timelineObservationSchema = z.object({
  timestamp: z.coerce.number(),
  type: z.enum(['Praise', 'Correction', 'Prevention', 'Off Task', 'Session Note', 'Timer']),
  count: z.number().nullable().optional(),
  tags: z.array(z.string() || z.number()),
  note: z.string().optional(),
  number_of_students: z.coerce.number().nullable().optional(),
  session_minute: z.number().nullable(),
});

export type timelineObservation = z.infer<typeof timelineObservationSchema>;

export const ObservationInstanceSchema = ObservationInstanceBaseSchema.extend({
  timer: z.array(
    z.object({
      timer_start: z.coerce.number(),
      timer_end: z.coerce.number(),
    })
  ),
  observations: z.array(timelineObservationSchema).optional(),
  rel_bld_notes: z.string().optional(),
  atsmosphere: z.boolean().optional(),
  solicits: z.boolean().optional(),
  positive_comments: z.boolean().optional(),
  quality_components: z.boolean().optional(),
  areas_strength: z.string().optional(),
  areas_growth: z.string().optional(),
  development_notes: z.string().optional(),
  defaulted_num_students: z.boolean().optional(),
});

export type ObservationInstance = z.infer<typeof ObservationInstanceSchema>;

export const getNewObservationInstance = (): ObservationInstance => {
  return {
    event_id: '',
    classroom_type: '',
    district_id: '',
    district_name: '',
    agency_id: '',
    agency_name: '',
    teacher: '',
    grade: '',
    subject: '',
    number_of_students: 0,
    activity: [],
    skills_posters: false,
    classroom_rules: false,
    schoolwide_rules: false,
    classroom_procedures: false,
    schoolwide_procedures: false,
    consequences: false,
    daily_schedule: false,
    cards_on_desk: false,
    students_write_cards: false,
    rel_bld_notes: '',
    atsmosphere: false,
    solicits: false,
    positive_comments: false,
    quality_components: false,

    timer: [],
  };
};
