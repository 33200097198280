// package imports
import { Box, Button, Skeleton, Tooltip } from '@mui/material';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import { useTheme } from '@emotion/react';
import { useRouter } from 'next/router';

// local imports
import { conversationHistoryKeys, useFetchConversationHistory } from './hooks/conversationHistoryhooks';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'services/hooks/useSearchParams';

const SMART_SEARCH_PATH = '/smart-search';

const Sidebar = ({ newConverstionHandler }) => {
  const theme = useTheme();
  const router = useRouter();
  const searchId = useSearchParams('cid');
  // todo - handle error status
  const { data, status } = useFetchConversationHistory();
  const queryClient = useQueryClient();

  const newConversation = () => {
    queryClient.invalidateQueries(conversationHistoryKeys.conversationHistory);
    newConverstionHandler();
    router.push(`/${SMART_SEARCH_PATH}`);
  };

  return (
    <>
      <Button
        sx={{
          fontSize: '.7rem',
          lineHeight: '1rem',
          backgroundColor: theme.palette.common.white,
          '&:hover,&:focus': {
            backgroundColor: theme.palette.common.white,
          },
          marginTop: '.4rem',
          marginBottom: '1rem',
        }}
        title='New Conversation'
        variant='outlined'
        color='primary'
        onClick={newConversation}
        startIcon={<MapsUgcIcon />}
      >
        <Box sx={{ display: { xs: 'none', lg: 'inline-block' } }}>New Conversation</Box>
      </Button>
      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        {status === 'success' &&
          data.map((conversation, index) => {
            return (
              <Box
                sx={{
                  color: theme.palette.primary.main,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  paddingTop: index === 0 ? '' : '.7rem',
                  cursor: 'pointer',
                  textDecoration: searchId === conversation.id ? 'underline' : 'none',
                }}
                key={conversation.id}
                onClick={() => {
                  const nextSearchParams = new URLSearchParams();
                  nextSearchParams.set('cid', conversation.id);
                  router.push(`/${SMART_SEARCH_PATH}?${nextSearchParams}`);
                }}
              >
                <Tooltip title={`${conversation.question}`}>
                  <span>{conversation.question}</span>
                </Tooltip>
              </Box>
            );
          })}
      </Box>
      {status !== 'success' &&
        Array.from({ length: 10 }).map((_, index) => (
          <Skeleton key={index} variant='text' animation={true} sx={{ width: '100%', fontSize: '18px', paddingTop: index === 0 ? '' : '.7rem' }} />
        ))}
    </>
  );
};

export default Sidebar;
