import { AIChatMessage } from '@microsoft/ai-chat-protocol';

export type ParsedMessage = {
  content: string;
  citations: string[];
  followupQuestions: string[];
  role: string;
  context?: object;
};

export function parseMessage(message: AIChatMessage): ParsedMessage {
  if (message.role === 'user') {
    return {
      content: message.content,
      citations: [],
      followupQuestions: [],
      role: message.role,
      context: message.context,
    };
  }

  const citations: string[] = [];
  const followupQuestions: string[] = [];

  // Extract any follow-up questions that might be in the message
  if (!message.content) {
    message.content = '';
  }
  let text = message.content
    .replaceAll(/<<([^>]+)>>/g, (_match, content: string) => {
      followupQuestions.push(content);
      return '';
    })
    .split('<<')[0] // Truncate incomplete questions
    .trim();

  text = text.replace(/\n/g, '<br />');

  // Extract any citations that might be in the message
  const parts = text.split(/\[([^\]]+)]/g);
  const result = parts.map((part, index) => {
    if (index % 2 === 0) {
      return part;
    }

    if (index + 1 < parts.length) {
      // Handle only completed citations
      let citationIndex = citations.indexOf(part);
      if (citationIndex === -1) {
        citations.push(part);
        citationIndex = citations.length;
      } else {
        citationIndex++;
      }

      return `<sup>[${citationIndex}]</sup>`;
    }

    return null;
  });

  return {
    content: result.join(' '),
    citations,
    followupQuestions,
    role: message.role,
    context: message.context,
  };
}
